import styled from 'styled-components';
import PageSection from '../../components/layout/PageSection';

export const SitemapSection = styled(PageSection)`
    height: 33vh;
    .pageSectionWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
`;