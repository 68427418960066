const sitemapCopy = {
    title: 'Sitemap',

    pageSeoTitle: 'Health Care Professional Resources for WELIREG™ (belzutifan)',
    pageDescription: 'See helpful resources about WELIREG™ (belzutifan) for health care professionals or request a callback from a representative.',
    pageKeywords: 'professional resources for welireg',

    links: [
        {
            title: 'Home',
            to: '/',
        },
        {
            title: 'What is VHL disease?',
            to: '/what-is-vhl/',
        },
        {
            title: 'VHL and genetics',
            to: '/vhl-and-genetics/',
        },
        {
            title: 'How is VHL diagnosed?',
            to: '/how-is-vhl-diagnosed/',
        },
        {
            title: 'Sitemap',
            to: '/sitemap/',
        },
        {
            title: '404 Error',
            to: '/404/',
        }
    ]
}

export default sitemapCopy;
