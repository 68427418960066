import React from 'react';
import sitemapCopy from '../copy/sitemap';
import Button from '../components/elements/Button';
import Hero from '../components/elements/Hero';
import * as s from '../styles/pages/sitemap';
import PageSeo from '../components/elements/Seo/PageSeo';

const Sitemap = () => {
  return (
    <>
    <PageSeo pageTitle={ sitemapCopy.pageSeoTitle } pageDescription={ sitemapCopy.pageDescription } pageKeywords={ sitemapCopy.pageKeywords }/>
        <Hero title={sitemapCopy.title} />
        <s.SitemapSection narrow>
            {sitemapCopy.links.map((link, i) => (
                <Button to={link.to} type = 'tertiary' border='none' key={i} data-design-category='button_vd'>
                { link.title }
                </Button>
            ))}
        </s.SitemapSection>
    </>
  )
}

export default Sitemap;
